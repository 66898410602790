import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { useAppDispatch, useAppSelector, setAppView } from '../state';
import { AppView } from '../types';
import '../styles/custom.scss';
import '../styles/main.scss';
import 'react-input-range/lib/css/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { HeaderOffer } from '../components/HeaderOffer';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { TitleComponent } from 'components/TitleComponent';
import { isBrowser } from '../utils';
import Loader from 'components/common/Loader';
import { AccountHeader } from '../components/AccountHeader';
import Getstarted from 'components/Getstarted';
import ErrorBoundary from 'components/ErrorBoundary';

interface Props {
  children: React.ReactNode;
  withTitle?: boolean;
  title?: string;
  description?: string;
}

export default ({ withTitle, children,title,description }: Props) => {
  const [mobileNavOpen, setMobileVisibility] = useState(false);
  const appView = useAppSelector(state => state.appState.appView);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      !location.pathname.includes('products') &&
      !location.pathname.includes('blog')
    ) {
      dispatch(setAppView(AppView.HOME));
    }
  }, [location.pathname]);
  return (
    <>
    <ErrorBoundary>
      {withTitle && <TitleComponent title={title} description={description||""}/>}
      <div
        className="page-wrapper"
        style={{ position: mobileNavOpen ? 'fixed' : 'static' }}
      >
        {appView === AppView.ACCOUNT ? (
          <>
            <HeaderOffer type="account-banner" />
            <AccountHeader
              setMobileVisibility={setMobileVisibility}
              mobileNavOpen={mobileNavOpen}
            />
          </>
        ) : (
          <>
            <HeaderOffer />
            <Header
              className="account"
              setMobileVisibility={setMobileVisibility}
              mobileNavOpen={mobileNavOpen}
            />
          </>
        )}

        {isBrowser() && (
          <Suspense fallback={<Loader />}>
            <div className="pos-rel">{children}</div>
          </Suspense>
        )}
        <Footer />
        <Getstarted />
      </div>
      </ErrorBoundary>
    </>
  );
};
