import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { CartIcon } from 'components/CartIcon';
import Overlay from 'components/Overlay';
import icn_menu from '../assets/icn_menu.png';
import { isLoggedIn, logout } from '../services/auth.api';
import { Logo } from './common/Logo';
import {
  removeBasket,
  useAppDispatch,
  useAppSelector,
  totalItemsCountSelector,
  createNewBasket,
} from 'state';
import useIsClient from '../hooks/useIsClient';
import PopConfirm from './PopConfirm';
import CheckoutDrawer from './CheckoutDrawer/CheckoutDrawer';

interface navItem {
  text: string;
  link: string;
}

let navItems: navItem[] = [
  {
    text: 'How it works',
    link: '/howitworks/',
  },
  {
    text: 'Ingredients',
    link: '/ingredients/',
  },
  {
    text: 'Pricing',
    link: '/pricing/',
  },
  {
    text: 'Our Story',
    link: '/ourstory/',
  },
  {
    text: 'Sustainability',
    link: '/sustainability/',
  },
  {
    text: 'Blog',
    link: '/blog/',
  },
  {
    text: 'Shop',
    link: '/products/',
  },
];

const showSustainability = process.env.SHOW_SUSTAINABILITY;
navItems =
  showSustainability === 'true'
    ? navItems
    : navItems.filter(item => item.text !== 'Sustainability');

export const Header = props => {
  const onGetStarted = () => {
    navigate('/getstarted/');
  };

  const dispatch = useAppDispatch();
  const { isClient, key } = useIsClient();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const totalItemsCount = useAppSelector(totalItemsCountSelector);

  const clearAndLogout = async () => {
    event.preventDefault();
    await dispatch(removeBasket());
    await dispatch(createNewBasket());//bug fix cart bug
    logout(() => navigate(`/?logout=true`));
  };

  const navItemsForMobile = () => {
    const navItemsForMobile = [...navItems];
    const [shopMenuItem] = navItemsForMobile.splice(
      navItemsForMobile.findIndex(item => item.text === 'Shop'),
      1,
    );
    navItemsForMobile.unshift(shopMenuItem);
    return navItemsForMobile;
  };

  const getMenuItem = (item: navItem) => {
    return (
      <Link to={item.link} activeClassName="active" className="menu-item">
        {item.text}
      </Link>
    );
  };

  return (
    <>
      <header
        className={classNames({ 'container-fluid': true, account: false })}
      >
        <div
          className={classNames({
            'menu-icn': true,
            open: props.mobileNavOpen,
          })}
          onClick={e => {
            e.preventDefault();
            props.setMobileVisibility(!props.mobileNavOpen);
          }}
        >
          <img src={icn_menu} title="menu-icon" alt="menu-icon" />
        </div>
        <Logo />
        <div
          className={classNames({ nav: true, 'nav-open': props.mobileNavOpen })}
        >
          <ul>
            {props.mobileNavOpen && (
              <li className="col-disp-md col-hide-lg menu-btn-or">
                {isLoggedIn() ? (
                  <Button
                    className="btn-def"
                    onClick={() => navigate('/account/dashboard/')}
                  >
                    myAccount
                  </Button>
                ) : (
                  <Button className="btn-def" onClick={onGetStarted}>
                    Order Now
                  </Button>
                )}
              </li>
            )}
            {props.mobileNavOpen && !isLoggedIn() && (
              <li className="log-wrapper">
                <Link
                  to="/login/"
                  activeClassName="active"
                  className="highlighted"
                >
                  Login
                </Link>
              </li>
            )}
            {props.mobileNavOpen
              ? navItemsForMobile().map((item, index) => (
                  <li key={index} className="col-disp-md menu-btn-or">
                    {getMenuItem(item)}
                  </li>
                ))
              : navItems.map((item, index) => (
                  <li
                    key={index}
                    className="col-hide-md col-disp-lg menu-btn-or"
                  >
                    {getMenuItem(item)}
                  </li>
                ))}
            {props.mobileNavOpen && isLoggedIn() && (
              <li className="log-wrapper">
                <PopConfirm title='Are you sure you want to log out?' submitLabel="Logout" action={clearAndLogout}>
                  <Link >Logout</Link>
                </PopConfirm>
              </li>
            )}
          </ul>
        </div>
        <div className="hdr-rt">
          <ul>
            {isClient ? (
              <li className="log-wrapper" key={key}>
                {isLoggedIn() ? (
                  <PopConfirm title='Are you sure you want to log out?' submitLabel="Logout" action={clearAndLogout}>
                    <Link >Logout</Link>
                  </PopConfirm>
                ) : (
                  <Link to="/login/" className="highlighted">
                    Login
                  </Link>
                )}
              </li>
            ) : null}
            <li className="btn-wrapper">
              {isLoggedIn() ? (
                <Button
                  className="btn-def"
                  onClick={() => navigate('/account/dashboard/')}
                >
                  myAccount
                </Button>
              ) : (
                <Button className="btn-def" onClick={onGetStarted}>
                  Order Now
                </Button>
              )}
            </li>
          </ul>
        </div>
        <div className="header-cart-icon">
          <CartIcon
            itemCount={totalItemsCount}
            onClick={() => setDrawerOpen(true)}
          />
        </div>
      </header>
      <Overlay show={drawerOpen} onClick={() => setDrawerOpen(false)} />
      <CheckoutDrawer
        open={drawerOpen}
        closeDrawer={() => setDrawerOpen(false)}
      />
    </>
  );
};
